@font-face {  
  font-family: "massallera";
  src: local("massallera"),
  url("./fonts/massallera.ttf") format("truetype");
  font-weight: bold;
}

@font-face {  
  font-family: "escolar_dotted";
  src: local("escolar_dotted"),
  url("./fonts/escolar_dotted.ttf") format("truetype");
  font-weight: bold;
}

body {
  margin: 0;
  font-family: "massallera";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.footer{
  position: fixed;
  bottom: 0%;
  width: 100%;
  height: 21px;
  background-color: #276958;
  color: white;
  text-align: center;
  box-shadow: 0px 1px 5px 3px lightgrey;
  font-size: 0.8rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.aviso{
  font-size: 1.2rem;
  text-align: center;
  background-color: rgb(82, 163, 141);
  color: white;
  border: 1px solid lightgrey;
  position: absolute;
  z-index: 100;
  width: 60%;
  padding: 51px 20px;
  box-shadow: lightgrey 0px 0px 10px 2px;
  border-radius: 4px;
  margin: 15% 17%;
}

@media (max-width: 600px) {
  .aviso{
    font-size: 1.2rem;
    text-align: center;
    background-color: rgb(82, 163, 141);
    color: white;
    border: 1px solid lightgrey;
    position: absolute;
    z-index: 100;
    width: 80%;
    margin: 15% 5%;
  }
}









