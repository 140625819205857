.blockActividades{
    display: flex;
    padding: 3%;
    flex-wrap: wrap;
}

.actividad{
    padding: 9px;
    box-shadow: 0px 1px 5px 3px lightgrey;
    margin: 15px;
    background-color: #52a38d;
    color: white;
    height: 125px;
    width: 150px;
    cursor: pointer;
    border-radius: 5px;
    position: relative;
}

.actividadNombre{
    font-size: 0.8rem;
    margin-top: 0px;
}

.actividadEdad{
    text-align: right;
    background-color: #ffffff36;
    padding: 3px;
    position: absolute;
    bottom: 6px;
    right: 0;
    border-top: 1px lightgrey solid;
    border-left: 1px lightgrey solid;
    border-bottom: 1px lightgrey solid;
    border-radius: 3px 0px 0px 3px;
}

.actividadEjemplo{
    background-color: white;
    height: 80%;
    margin: 6px -9px;
    box-shadow: inset 0 0 5px #c8c8c8;
}

.menu {
    padding: 15px;
    cursor:pointer;
}
.menuActive{
    background-color: #5ca48d45;
    border: 1px #5ca48d solid;
    border-bottom: 0px;
}