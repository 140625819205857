.itemOpc{
    width: 33%;
    cursor: pointer;
    border: 1px lightgrey solid;
    text-align: center;
    padding: 0px 10px;
    margin: 3px;
    font-size: 25px;
}

.itemOpc:hover, .itemOpc:active{
    background-color: lightblue;
}