.blockMenu{
    display: flex;
    box-shadow: 0px 1px 5px 4px lightgrey;
    background-color: white;
    height: 50px;
    position: fixed;
    top: 0px;
    width: 100%;
    font-size: 0.85rem;
}

.itemMenu{
    color: #276958;
    padding: 20px;
    cursor: pointer;
}